import React from 'react'
//import { Card, Text } from 'theme-ui'
//import Section from '@components/Section'
//import Navigation from '@components/Navigation'
//import useSiteMetadata from '@helpers/useSiteMetadata'
//import attachSocialIcons from '@helpers/attachSocialIcons'

const Social = () => {
  //const { social } = useSiteMetadata()
  return (<></>)
  /*
  return (
    <Section aside title="Hadi Konuşalım">
      <Card variant='paper'>
        <Text variant='p'>
          Yaptığım işle ilgili merak ettikleriniz mi var? İş hakkında mı konuşmak istiyorsunuz ?
          Hadi Konuşalım
        </Text>
        <Navigation items={attachSocialIcons(social)} iconOnly />
      </Card>
    </Section>
  )

   */
}

export default Social

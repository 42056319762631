import React from 'react'
import { Link } from 'gatsby'
import { Button, Box, Heading, Text } from 'theme-ui'

/**
 * Shadow me to add your own content
 */

const styles = {
  author: {
    display: `inline-block`,
    color: `alpha`
  },
  occupation: {
    mb: 4
  },
  specialty: {
    color: `text`,
    mb: 4
  }
}

export default () => (
  <>
    <Heading variant='h1'>
      Merhaba, ben <Text sx={styles.author}>Ömer Harun Çetin</Text>.
    </Heading>
    <Heading variant='h1' sx={styles.occupation}>
      Yazılım & Blokzincir & Akıllı Kontrat Geliştiricisi
    </Heading>
    <Box variant='buttons.group'>
      <Button as={Link} to='/contact'>
        İletişim
      </Button>
      <Button variant='white' as={Link} to='/about'>
        Hakkımda
      </Button>
    </Box>
  </>
)
